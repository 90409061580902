<template>
    <div>
        <div v-if="loadding" class="loaddind_box">
            <div class="loader"></div>
            <div class="text">
                加载中...
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        loadding: {
            type: Boolean,
        },
    },
    data() {
        return {}
    },
    methods: {},
    mounted() { },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style scoped lang="scss">
.loaddind_box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999999;

    .text {
        font-size: 35px;
        color: #fff;
        font-weight: 400;
        margin-top: 20px;
    }


    /* HTML: <div class="loader"></div> */
    .loader {
        width: 70px;
        aspect-ratio: 1;
        display: grid;
    }

    .loader::before,
    .loader::after {
        content: "";
        grid-area: 1/1;
        --c: no-repeat radial-gradient(farthest-side, #25b09b 92%, #0000);
        background:
            var(--c) 50% 0,
            var(--c) 50% 100%,
            var(--c) 100% 50%,
            var(--c) 0 50%;
        background-size: 12px 12px;
        animation: l12 1s infinite;
    }

    .loader::before {
        margin: 4px;
        filter: hue-rotate(45deg);
        background-size: 8px 8px;
        animation-timing-function: linear
    }

    @keyframes l12 {
        100% {
            transform: rotate(.5turn)
        }
    }
}
</style>