<template>
    <div class="box" v-if="show">
        <!-- 头部 -->
        <div class="head">
            <div @click="fanhuii" class="img_box">
                <img src="../assets/image/fanhui.png" alt="">
            </div>
            <div class="title">人脸认证</div>
        </div>
        <div style="height: 50px;"></div>

        <!-- 人脸验证 -->
        <div class="Humanface">
            <div class="tishi">{{ Prompttext }}</div>
            <div class="vode_box" ref="vode">
                <!-- <div v-if="!Startverification" class="renlain">
                    <img src="../assets/image/huoti.png" alt="">
                </div> -->
                <video id="videoxs" style="object-fit: cover;" ref="video" autoplay playsinline
                    mode="scaleToFill"></video>
            </div>
            <!-- 动作提示 -->
            <div class="Action_cue" v-if="dongzuo && currentTaskId != 3">
                <div class="Action_one" v-if="currentTaskId == 1">
                    <img src="../assets/image/dd.gif" alt="">
                </div>
                <div class="Action_one" v-if="currentTaskId == 2">
                    <img src="../assets/image/zz.gif" alt="">
                </div>
                <div class="shuliang">
                    <div :class="{ 'active': currentTaskId == 1 }">1</div>
                    <div :class="{ 'active': currentTaskId == 2 }">2</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as faceapi from 'face-api.js';
// const faceapi = new Worker('face-api.js');

export default {
    props: {
        show: {
            type: Boolean,
        },
    },
    data() {
        return {
            video: null,
            Prompttext: '请将脸部移入框内中心，脸部距离镜头尽量远一点，等待系统识别',
            timer: null,//人脸实时监测
            currentTaskId: 1,//当前正在执行的任务 1=摇头  2=眨眼  3=完成所有任务
            Shakehead: true,//摇头判断
            mouth: true,//张嘴判断
            mouthOpenThreshold: 0.15,//张嘴的幅度，数值越大嘴越大

            Startverification: true,//是否已经开启验证状态了
            dongzuo: false,
        };
    },
    watch: {
        show(newVal, oldVal) {
            if (newVal == true) {
                this.$nextTick(() => {
                    this.initializeVideo();
                })
            }
        }
    },
    mounted() {},

    methods: {
        fanhuii() {
            this.mouth = true
            this.currentTaskId = 1
            this.$emit('send-data', 12);
            this.stopCamera()
        },
        //开始验证按钮 暂时不用
        initiate() {
            this.Startverification = true
            this.initializeVideo();
        },
        // 关闭摄像头
        stopCamera() {
            const tracks = this.video.srcObject.getTracks();
            tracks.forEach(track => track.stop());
            this.video.srcObject = null;
        },
        //初始化获取视频流
        async initializeVideo() {
            console.log(this.$refs.video, '触发了');
            this.video = this.$refs.video;
            const stream = await navigator.mediaDevices.getUserMedia({ video: {} });
            this.video.srcObject = stream;
            this.video.addEventListener('play', () => {
                this.loadModels()
                    .then(() => {
                        console.log('调用模型成功');
                        this.timer = window.setInterval(this.detectFace, 300);
                    })
                    .catch((err) => {
                        console.log('模型失败');
                    })
            });
        },

        //加载模型
        async loadModels() {
            const modelPath = '/models'; // 确保这个路径指向你的模型文件所在位置
            try {
                await faceapi.nets.tinyFaceDetector.loadFromUri('./models');
                await faceapi.nets.faceLandmark68Net.loadFromUri('./models');
                await faceapi.nets.faceExpressionNet.loadFromUri('./models');
                console.log('模型加载成功');
            } catch (error) {
                console.error('模型加载失败:', error);
            }
        },
        //检测人脸
        async detectFace() {
            const detections = await faceapi.detectAllFaces(this.video, new faceapi.TinyFaceDetectorOptions({inputSize: 128})).withFaceLandmarks();
            console.log(detections, '检测人脸');

            if (detections.length > 0) {//如果检测到人脸
                console.log(this.currentTaskId, '当前任务');
                this.dongzuo = true
                if (this.currentTaskId == 1) { //检测摇头   
                    console.log('点头');
                    this.Prompttext = '请缓慢点头'
                    this.calculateYaw(detections)
                } else if (this.currentTaskId == 2) {//检测眨眼
                    this.detectingBlink(detections)
                } else {

                }
                this.faceDetected = true;
            } else {//没有检测到人脸
                console.log('人脸未进入');
                this.faceDetected = false;
            }
        },
        //点头判断
        async calculateYaw(detections) {
            const noseIndex = 27; // 鼻子相关的特征点索引, 第28个点索引为27（因为索引从0开始）
            detections.forEach(detection => {
                const landmarks = detection.landmarks;
                const unshiftedLandmarks = detection.unshiftedLandmarks;

                // 获取对齐后的鼻子特征点坐标
                const alignedNosePoint = landmarks._positions[noseIndex];

                // 获取对齐前的鼻子特征点坐标
                const originalNosePoint = unshiftedLandmarks._positions[noseIndex];

                // 计算鼻子点的偏移量
                const noseOffset = {
                    x: alignedNosePoint.x - originalNosePoint.x,
                    y: alignedNosePoint.y - originalNosePoint.y
                };
                console.log(noseOffset, '111');
                if (noseOffset.y < 195 || noseOffset.y > 205 && this.Shakehead) {
                    console.log('通过点头判断');
                    this.Shakehead = false
                    setTimeout(() => {
                        this.Prompttext = '请张嘴'
                        this.currentTaskId = 2
                    }, 2000)
                }
            });
        },
        //张嘴判断
        async detectingBlink(detections) {
            detections.forEach(detection => {
                const landmarks = detection.landmarks;

                // 获取嘴巴的关键点
                const mouth = landmarks.getMouth();
                console.log(mouth);

                // 计算 MAR
                const mar = this.calculateMAR(mouth);
                console.log(mar, '张嘴');

                // 判断是否张嘴
                if (mar > this.mouthOpenThreshold && this.mouth) {
                    console.log('张嘴了');
                    this.mouth = false
                    setTimeout(() => {
                        this.currentTaskId = 3
                        if (this.timer) {
                            this.Prompttext = '验证完成'
                            this.mouth = true
                            this.dongzuo = false
                            clearInterval(this.timer)
                            this.$emit('send-data', 9);
                            setTimeout(() => {
                                this.currentTaskId = 1
                                this.Prompttext = '请将脸部移入框内中心，脸部距离镜头尽量远一点，等待系统识别'
                                this.stopCamera()
                            }, 3000)
                        }
                    }, 1000)
                } else {
                    console.log('没张嘴');
                }
            });
        },
        //计算张嘴方法
        euclideanDistance(point1, point2) {
            return Math.sqrt(Math.pow((point1.x - point2.x), 2) + Math.pow((point1.y - point2.y), 2));
        },
        //计算张嘴方法
        calculateMAR(mouthLandmarks) {
            // 计算嘴巴上下两组关键点之间的距离，用于计算纵向距离
            const A = this.euclideanDistance(mouthLandmarks[13], mouthLandmarks[19]);
            const B = this.euclideanDistance(mouthLandmarks[14], mouthLandmarks[18]);
            const C = this.euclideanDistance(mouthLandmarks[15], mouthLandmarks[17]);

            // 计算嘴巴两侧关键点之间的距离，用于计算横向距离
            const D = this.euclideanDistance(mouthLandmarks[12], mouthLandmarks[16]);

            return (A + B + C) / (3.0 * D);
        },
    },
    beforeDestroy() {
        console.log('清除定时器了', 'beforeDestroy');
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
    destroyed() {
        console.log('清除定时器了', 'destroyed');
        if (this.timer) {
            clearInterval(this.timer)
        }
    }
};
</script>

<style scoped lang="scss">
.box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
}

.head {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 120px;
    border-bottom: 3px solid #004ea2;
    display: flex;
    align-items: center;
    justify-content: center;

    .img_box {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 40px;

        img {
            width: 25px;
            height: 23px;
        }
    }

    .title {
        font-size: 40px;
        color: #222222;
        font-weight: bold;
        letter-spacing: .1em;
    }
}

.Humanface {
    padding: 40px 75px;

    .tishi {
        font-weight: bold;
        font-size: 33px;
        letter-spacing: .05em;
        color: #222222;
        margin-bottom: 60px;
        margin-top: 30px;
    }

    .tishione {
        font-weight: bold;
        font-size: 25px;
        letter-spacing: .05em;
        color: #222222;
        margin: 50px 0;
    }

    .tishitow {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tihsibox {
            display: flex;
            flex-direction: column;
            align-items: center;

            .img_box {
                width: 100px;
                height: 100px;
                margin-bottom: 20px;
                background: #F1F1F6;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                img {
                    width: 53px;
                    height: 53px;
                }
            }

            div {
                font-size: 20px;
                color: #A8ABB2;

            }
        }
    }
}

button {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    background: #3F84FB;
    color: #fff;
    border: 0;
    margin: 0;
    padding: 25px 0;
    font-size: 32px;
    width: 80%;
    border-radius: 50px;
}

.Action_cue {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;

    .Action_one {
        // background: #C4C2C4;
        width: 160px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        img {
            width: 70%;
            height: 70%;
        }
    }

    .shuliang {
        display: flex;
        align-items: center;
        margin-top: 20px;

        div {
            width: 40px;
            height: 40px;
            border: 2px solid #3F84FB;
            border-radius: 50%;
            font-size: 30px;
            margin: 0 10px;
            text-align: center;
            line-height: 40px;
        }
    }
}

.active {
    color: #fff;
    background: #3F84FB;
}

.vode_box {
    width: 450px;
    height: 450px;
    border: 8px solid #004ea2;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    padding: 3px;

    .renlain {
        width: 50%;
        height: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);


        img {
            width: 100%;
            height: 100%;
        }
    }

    #videoxs {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}


.Text_prompt {
    /* position: fixed;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 30px;
    font-weight: bold; */
}
</style>