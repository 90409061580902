<template>
    <div>
        <van-popup v-model="show" :close-on-click-overlay="false">
            <div class="registration_title">
                征信授权签署
                <img class="border_img" src="../assets/image/border.png" alt="">
            </div>
            <div class="box">
                <div class="box1">
                    <div class="agreement">
                        <div v-html="zhengxin"></div>
                    </div>
                    <div class="agree_box">
                        <van-checkbox checked-color="#004ea2" v-model="checked">
                            <div style="display: flex;align-items: center;">
                                <div>我已阅读并同意</div>
                                <span>“征信授权同意书”</span>
                            </div>
                        </van-checkbox>

                    </div>

                    <div class="Drop_box" @click="pulldown">
                        <div class="yongtu">贷款用途</div>
                        <div class="xuanze">
                            <div :style="{ 'color': useListIndex == -1 ? '#666666' : '#222222' }">{{
                                useListIndex ==
                                    -1 ? '请选择' : useList[useListIndex] }}</div>
                            <img src="../assets/image/right.png" alt="">
                        </div>
                    </div>

                    <div class="Drop_box">
                        <div class="yongtu">签署日期</div>
                        <div class="yongtu">{{ currentDate }}</div>
                    </div>
                    <button :disabled="noClick" @click="shenqing" class="anniu">申请贷款</button>
                </div>
            </div>
        </van-popup>

        <van-popup v-model="classPicker" position="right">
            <van-picker show-toolbar :columns="useList" @confirm="setStudentClass" @cancel="classPicker = false" />
        </van-popup>
    </div>
</template>
<script>
import { Toast } from 'vant';

export default {
    name: 'registration',
    props: {
        show: {
            type: Boolean,
            value: true,// true=显示 false=隐藏
        },
    },
    data() {
        return {
            checked: false,//是否同意了征信同意书  
            classPicker: false,
            useList: [],
            useListIndex: -1,
            currentDate: '',
            currentDates: '',
            zhengxin: '',//征信书
            noClick: false
        }
    },
    methods: {
        shenqing() {
            if (!this.checked) {
                Toast('请勾选征信授权同意书')
                return
            } else if (this.useListIndex == -1) {
                Toast('请选择申请用途')
                return
            }
            this.noClick = true
            this.$request({
                url: '/Index/authorized_credit',
                data: {
                    examine_id: sessionStorage.getItem('examine_id'),
                    apply_purpose: this.useList[this.useListIndex],//用途
                    sign_date: this.currentDates //日期
                }
            }).then(res => {
                console.log(res, '申请成功');
                if (res.code == 1) {
                    this.$emit('send-data', 5, this.useListIndex);
                    setTimeout(() => {
                        // window.scrollTo({
                        //     top: 0,
                        //     behavior: 'smooth' // 平滑滚动效果
                        // });
                        this.useListIndex = -1
                        this.checked = false
                        // Toast(res.msg)
                    }, 3000)
                } else {
                    Toast(res.msg)
                }
                setTimeout(() => {
                    this.noClick = false
                }, 2000)
            }).catch(err => {
                setTimeout(() => {
                    this.noClick = false
                }, 2000)
                console.log(err, '申请失败');
                Toast(err.msg)
            })
        },
        pulldown() {
            this.classPicker = true
        },
        setStudentClass(picker, values) {
            this.classPicker = false
            this.useListIndex = values
        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
            const day = String(today.getDate()).padStart(2, '0');
            this.currentDate = `${year}.${month}.${day}`;
            this.currentDates = `${year}-${month}-${day}`;
        },
        //获取菜单列表
        getmenuList() {
            this.$request({
                url: '/Index/get_select_data',
                data: {
                    // 1=婚姻，2=房产类型，3=所属行业，4=单位类型，5=与联系人关系，6=申请用途
                    type: 6
                }
            }).then(res => {
                const list = [];
                res.data.forEach(item => {
                    list.push(item.name)
                });
                this.useList = list
            }).catch(err => {
                console.log(err, '下拉失败');
            })
        },
        //获取正在你书
        getzhengxin() {
            this.$request({
                url: '/Index/get_authorized_credit',
                data: {
                    // 1=婚姻，2=房产类型，3=所属行业，4=单位类型，5=与联系人关系，6=申请用途
                    type: 6
                }
            }).then(res => {
                console.log(res, '征信书');
                this.zhengxin = res.data.info
            }).catch(err => {
            })
        },
    },
    mounted() { this.getmenuList() },
    watch: {
        show(newVal, oldVal) {
            if (newVal == true) {
                this.$nextTick(() => {
                    this.getzhengxin()
                    this.getCurrentDate()
                    this.getmenuList()
                })
            }
        }
    },
    computed: {},
    filters: {},
    components: {}
}
</script>
<style>
.van-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.van-popup--center {
    background-color: rgba(0, 0, 0, 0);
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}

.van-checkbox__icon {
    font-size: 22px !important;
}
</style>
<style scoped lang="scss">
.registration_title {
    width: 73vw;
    height: 120px;
    background: #004ea2;
    border-radius: 32px 32px 0px 0px;
    line-height: 120px;
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}

.box1 {
    padding: 70px 0;
    width: 75%;
    height: 80vh;
    overflow-y: auto;
}

.box {
    width: 73vw;
    height: 87vh;
    background: #fff;
    box-sizing: border-box;
    border-radius: 0px 0px 32px 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .agreement {
        padding: 20px;
        width: 100%;
        height: auto;
        background: #F9F9F9;
        border: 1px solid #EEEEEE;
        font-weight: 400;
        font-size: 30px;
        color: #222222;
        text-align: left;
    }

    .agree_box {
        width: 100%;
        margin: 65px 0 88px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
            margin-right: 20px;
            font-weight: 400;
            font-size: 35px;
            color: #222222;
        }

        span {
            font-weight: 400;
            font-size: 35px;
            color: #004ea2;
        }
    }

    .Drop_box {
        padding: 0 30px;
        width: 100%;
        height: 96px;
        margin: 0 auto;
        margin-bottom: 40px;

        background: #F4F4F4;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .yongtu {
            font-weight: 400;
            font-size: 32px;
            color: #222222;
        }

        .xuanze {
            display: flex;
            align-items: center;

            div {
                font-weight: 400;
                font-size: 32px;
                color: #666666;
                margin-right: 20px;
            }

            img {
                width: 25px;
                height: 25px;
            }
        }
    }

    .anniu {
        width: 620px;
        height: 100px;
        background: #004ea2;
        border-radius: 48px;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        border: 0;
        margin-top: 60px;
    }
}
</style>