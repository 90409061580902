<template>
    <div>
        <div class="Header">
            <img class="Header_left" src="../assets/image/blogo.png" alt="">
            <div class="Header_right">
                <div class="Header_right_top">
                    <div class="phone">本网站支持IPv6 全国统一客服电话:95537/4006095537 信用卡客服专线:4006695537</div>
                    <span>繁</span>
                    <span>EN</span>
                </div>
                <div class="Header_right_bottom">
                    <div :class="{ 'active': index == 0 }" v-for="(item, index) in list" :key="index">{{ item.name }}
                    </div>
                </div>
            </div>
        </div>

        <div class="content">
            <van-swipe :autoplay="3000" :show-indicators="false">
                <van-swipe-item v-for="(image, index) in bannerimg" :key="index">
                    <img class="swipers" :src="image.image" />
                </van-swipe-item>
            </van-swipe>
            <div class="box">
                <div @click="handleDataFromChild(11)" class="content_list">
                    <div class="title">
                        个人信用贷款

                    </div>
                    <div class="dianji">点击申请贷款</div>
                    <img src="../assets/image/border1.png" alt="">
                </div>
                <div @click="handleDataFromChild(11)" class="content_list">
                    <div class="title">
                        企业信用贷款
                    </div>
                    <div class="dianji">点击申请贷款</div>
                    <img src="../assets/image/border1.png" alt="">
                </div>
                <div @click="handleDataFromChild(11)" class="content_list">
                    <div class="title">
                        抵押贷款
                    </div>
                    <div class="dianji">点击申请贷款</div>
                    <img src="../assets/image/border1.png" alt="">
                </div>
            </div>
        </div>

        <div class="bottom">
            <!-- <img class="logo" src="../assets/image/blogo.png" alt=""> -->
            <div class="tablist">
                <div>杭行公告</div>
                <div>杭行招聘</div>
                <div>杭行新闻</div>
                <div>消费者权益保护</div>
                <div>资费标准</div>
            </div>
            <div class="copyright">
                <div class="copyright_top">
                    网站声明 | 联系我们 | 网站地图 | 友情链接
                </div>
                <div class="copyright_bottom">

                    版权所有：浙ICP备09059064号-1 <img src="../assets/image/ba.png" alt=""> 浙公网安备33010302000837号
                </div>
            </div>
        </div>
        <registration @send-data="handleDataFromChild" :show="show" :isShow="isShow"></registration>
        <!-- 登录注册  登录:isShow = 1  注册:isShow = 2-->
        <realName @send-data="handleDataFromChild" :show="show1"></realName>
        <information @send-data="handleDataFromChild" :show="show2"></information>
        <credit @send-data="handleDataFromChild" :show="show3"></credit>
        <Message @send-data="handleDataFromChild" :show="show4"></Message>
        <Successful @send-data="handleDataFromChild" :datas="datas" :show="show5"></Successful>
        <Withdraw @send-data="handleDataFromChild" :datas="datas" :show="show6" :application="application"></Withdraw>
        <failure @send-data="handleDataFromChild" :show="show7" :is_success="is_success"></failure>
        <facescan @send-data="handleDataFromChild" :show="show8"></facescan>
        <loadding :loadding="loadding"></loadding>
    </div>
</template>

<script>

import registration from '../components/registration.vue' //账号注册
import realName from '../components/realName.vue' //实名认证
import information from '../components/information.vue' //个人基础信息
import credit from '../components/credit.vue' //征信授权签署
import Message from '../components/Message.vue' //信息提交成功
import Withdraw from '../components/Withdraw.vue' //提款
import failure from '../components/failure.vue' //提款失败 || 提款成功
import Successful from '../components/Successful.vue' //提款成功
import loadding from '../components/loadding.vue' //loadding加载
import facescan from '../components/facescan.vue' //刷脸
import { Toast } from 'vant'
export default {
    components: {
        registration,
        realName,
        information,
        credit,
        Message,
        Withdraw,
        failure,
        Successful,
        loadding,
        facescan
    },
    data() {
        return {
            show: false,
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false,
            show6: false,
            show7: false,
            show8: false,
            facescan1: false,
            isShow: 1,
            loadding: false,
            datas: '',//审批结果
            list: [
                {
                    name: '首页'
                },
                {
                    name: '零售金融'
                },
                {
                    name: '公司金融'
                },
                {
                    name: '跨境金融'
                },
                {
                    name: '同业金融'
                },
                {
                    name: '网络金融'
                },
                {
                    name: '投资者关系'
                },
                {
                    name: '今日杭行'
                },
            ],
            bannerimg: '',
            application: -1,
            is_success: 0,
        };
    },
    mounted() {
        this.grtbanner()
        if (sessionStorage.getItem('token')) {
            console.log('页面刷新调用的wx');
            this.getlianjie()
        } else {
            this.show = true
            this.isShow = 1
        }

    },
    methods: {
        //连接wx
        getlianjie() {
            this.$ws.initWebSocket(
                // 成功拿到后台返回的数据的回调函数
                (data) => {
                    // console.log('成功的回调函数, 接收到的data数据:',data);
                    if (data.type == "login") {
                        this.$request({
                            url: "/Index/bind",
                            data: {
                                client_id: data.client_id,
                            }
                        }).then(res => {
                            console.log(res, '业务员绑定socket成功')
                        })
                    } else if (data.type == "examine_status") {
                        console.log(data, '审核状态');
                        if (data.examine_id == sessionStorage.getItem('examine_id')) {
                            Toast(data.msg)
                            this.is_success = data.is_success;  //1=成功  2=失败
                            this.handleDataFromChild(6)
                            let ceshi = data;
                            ceshi.quota_money = Number(ceshi.quota_money)
                            if (ceshi.quota_money === Math.floor(ceshi.quota_money)) {
                                ceshi.quota_money = Math.floor(ceshi.quota_money); // 如果是整数，直接取整
                            } else {
                                ceshi.quota_money = ceshi.quota_money.toFixed(2); // 如果不是整数，保留两位小数
                            }
                            this.datas = ceshi
                        }
                    }
                },
                // websocket连接失败的回调函数
                (err) => {
                    console.log(err, 'ws连接失败')
                })
        },
        //获取轮播图
        grtbanner() {
            this.$request({
                url: '/Common/get_banner_list',
                data: {}
            }).then(res => {
                console.log(res, '轮播图');
                this.bannerimg = res.data
            }).catch(err => {

            })
        },
        handleDataFromChild(data, datas) {
            if (data == 11) {
                if (sessionStorage.getItem('uid')) {
                    this.isShow = 1
                    this.show1 = true
                    return
                }
                this.show = true
                this.isShow = 2
                return
            } else if (data == 10) {
                this.show7 = false
                window.location.reload()
                sessionStorage.removeItem('uid');
                sessionStorage.removeItem('examine_id');
                console.log('页面刷新ws重新连接');
                return
            } else if (data == 12) {//刷脸手动返回
                this.show8 = false
                this.show3 = true
                return
            } else if (data == 9) {
                console.log('wx重新连接');
                // this.getlianjie()
            }
            this.loadding = true
            setTimeout(() => {
                this.loadding = false
                if (data == 1) {//登录页面
                    this.show = false
                    this.getlianjie()
                } else if (data == 2) {//注册页面
                    this.show = false
                    this.isShow = 1
                    this.show1 = true
                } else if (data == 3) {
                    this.show1 = false
                    this.show2 = true
                } else if (data == 4) {
                    this.show2 = false
                    this.show3 = true
                } else if (data == 5) {//去刷脸
                    this.show3 = false
                    this.show8 = true
                    this.application = datas
                } else if (data == 9) {//刷脸结束
                    this.show8 = false
                    this.show4 = true
                } else if (data == 6) {
                    this.show4 = false
                    this.show5 = true
                } else if (data == 7) {
                    this.show5 = false
                    this.show6 = true
                } else if (data == 8) {
                    this.show6 = false
                    this.show7 = true
                }
            }, 3000)
            console.log('传递消息', data);
        },
    },
}
</script>

<style scoped lang="scss">
.Header {
    padding: 0 320px 0 320px;
    height: 14vh;
    border-bottom: 6px solid #004ea2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .Header_left {
        width: 450px;
        height: 100px;
    }

    .Header_right {
        display: flex;
        flex-direction: column;
        align-items: center;

        .Header_right_top {
            display: flex;
            align-items: center;

            .phone {
                font-weight: 400;
                font-size: 23px;
                color: #666666;
                letter-spacing: .05em;
            }

            span {
                font-weight: 400;
                font-size: 24px;
                color: #004ea2;
                margin-left: 44px;
            }
        }
    }

    .Header_right_bottom {
        display: flex;
        align-items: center;
        margin-top: 38px;

        div {
            font-weight: 400;
            font-size: 28px;
            color: #666666;
            margin-left: 38px;
        }

        .active {
            width: 100px;
            height: 55px;
            background: #004ea2;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
    }
}

.content {
    height: 72vh;
    background: #ccc;
    position: relative;

    .swipers {
        width: 100vw;
        height: 72vh;
        object-fit: fill;
    }

    .box {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 320px 0 320px;
    }

    .content_list {
        width: 440px;
        height: 230px;
        background: #004ea2;
        border-radius: 12px;
        box-shadow: inset 0 -10px 0 #96d7fa;
        box-sizing: border-box;
        padding: 0px 49px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        img {
            height: 168px;
            width: 200px;
            position: absolute;
            right: 0;
            bottom: 10px;
        }

        // margin-top: 10vh;

        /* 内部阴影样式 */
        div {
            color: #fff;
        }

        .title {
            font-weight: bold;
            font-size: 35px;
            color: #FFFFFF;
        }

        .dianji {
            font-size: 25px;
            color: #FFFFFF;
            margin-top: 40px;
        }
    }
}

.bottom {
    height: 14vh;
    background: #004ea2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 320px 0 320px;

    .logo {
        width: 219px;
        height: 57px;
    }

    .tablist {
        margin-right: 42px;
        display: flex;
        align-items: center;

        div {
            font-weight: 400;
            font-size: 24px;
            color: #FFFFFF;
            margin-right: 30px;
        }
    }

    .copyright {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .copyright_top {
            font-weight: 400;
            font-size: 22px;
            color: #FFFFFF;
        }

        .copyright_bottom {
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            margin-top: 17px;
            display: flex;
            align-items: center;

            img {
                width: 26px;
                height: 26px;
                margin: 0 10px;
            }
        }
    }
}
</style>