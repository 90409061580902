<template>
    <div>
        <van-popup v-model="show" :close-on-click-overlay="false">
            <div class="registration_title">
                提款金额
                <img class="border_img" src="../assets/image/border.png" alt="">
            </div>
            <div class="box">
                <div class="box1">
                    <div class="tishi11">提款金额</div>
                    <div class="Withdrawal">
                        <input v-model="withdrawalamount" class="jine" type="number" placeholder="请输入提款金额">
                        <div>最高额度：{{ datas.quota_money }}元</div>
                    </div>


                    <div class="Drop_box" @click="pulldown(1)">
                        <div class="yongtu">提款期数</div>
                        <div class="xuanze">
                            <div :style="{ 'color': useListIndex == -1 ? '#666666' : '#222222' }">{{
                                useListIndex ==
                                    -1 ? '请选择' : useList[useListIndex] }}</div>
                            <img src="../assets/image/right.png" alt="">
                        </div>
                    </div>

                    <div class="Drop_box">
                        <div class="yongtu">年利率</div>
                        <div class="yongtu">{{ datas.annual_interest_rate ? datas.annual_interest_rate : 0 }}%</div>
                    </div>

                    <div class="Drop_box" @click="pulldown(2)">
                        <div class="yongtu">贷款用途</div>
                        <div class="xuanze">
                            <div :style="{ 'color': purposeofloanIndex == -1 ? '#666666' : '#222222' }">{{
                                purposeofloanIndex ==
                                    -1 ? '请选择' : purposeofloan[purposeofloanIndex] }}</div>
                            <img src="../assets/image/right.png" alt="">
                        </div>
                    </div>

                    <div class="Drop_box" @click="pulldown(3)">
                        <div class="yongtu">还款方式</div>
                        <div class="xuanze">
                            <div :style="{ 'color': repaymentmethodIndex == -1 ? '#666666' : '#222222' }">{{
                                repaymentmethodIndex ==
                                    -1 ? '请选择' : repaymentmethod[repaymentmethodIndex] }}</div>
                            <img src="../assets/image/right.png" alt="">
                        </div>
                    </div>

                    <div class="startstop">
                        <div class="qizhi">
                            <div>起止日期</div>
                            <div>{{ currentDate }}至{{ enddate }}</div>
                        </div>
                        <div class="qizhi">
                            <div>首次还款日</div>
                            <div>{{ proximodate }}</div>
                        </div>
                        <div class="qizhi">
                            <div>固定还款日</div>
                            <div>每月{{ No }}号</div>
                        </div>
                    </div>

                    <div class="Drop_box">
                        <div class="zhangha">提款账号</div>
                        <input v-model="withdrawmoney" placeholder="请输入" type="number">
                    </div>

                    <div class="Drop_box">
                        <div class="zhangha">还款账号</div>
                        <input v-model="repaymentwithdrawal" placeholder="请输入" type="number">
                    </div>
                    <button :disabled="noClick" @click="next" class="anniu">下一步</button>
                </div>
            </div>
        </van-popup>

        <van-popup v-model="classPicker" position="right">
            <van-picker show-toolbar :columns="commonality" @confirm="setStudentClass" @cancel="classPicker = false" />
        </van-popup>
    </div>
</template>
<script>
import { Toast } from 'vant';
import dayjs from 'dayjs';
export default {
    name: 'registration',
    props: {
        show: {
            type: Boolean,
        },
        //年利率   额度
        datas: {
            type: String
        },
        application: {
            type: Number
        }
    },
    data() {
        return {
            checked: false,
            classPicker: false,
            currentIndex: -1,//公共下标
            commonality: [],//公共弹框列表

            //提款金额
            withdrawalamount: '',
            //提款期数
            useList: [],
            useListIndex: -1,
            currentDate: dayjs().format('YYYY.MM.DD'),//当前日期
            enddate: '',//结束日期
            proximodate: '',//下个月日期
            No: dayjs().format('DD'),//每个月多少号
            //贷款用途
            purposeofloan: [],
            purposeofloanIndex: -1,
            //还款方式
            repaymentmethod: [],
            repaymentmethodIndex: -1,
            //提款账号
            withdrawmoney: '',
            //还款账号
            repaymentwithdrawal: '',
            timer: null,
            index: 6,

            // 提交格式
            tijiaotimer: dayjs().format('YYYY-MM-DD'),//当前日期
            tijiaotimer1: "",//起止日期的结束日期
            tijiaotimer2: "",//当前日期下个月的日期
            noClick: false,
        }
    },
    methods: {
        //计算贷款日期
        gettimer(monthsToAdd) {
            console.log(monthsToAdd, '传递的参数');
            // 获取当前日期
            let currentDate = dayjs(this.currentDate);
            // 添加指定的月数
            let newDate = currentDate.add(monthsToAdd, 'month');
            let guding = currentDate.add(1, 'month')
            // 确保新日期的日期部分不超过当前日期的最大日期
            let currentDay = currentDate.date(); // 当前日期的日期部分
            let maxDayOfMonth = newDate.endOf('month').date(); // 下个月的最大日期
            // 如果当前日期的日期部分大于下个月的最大日期，则取下个月的最大日期
            if (currentDay > maxDayOfMonth) {
                newDate = newDate.set('date', maxDayOfMonth);
            } else {
                newDate = newDate.set('date', currentDay);
            }



            // 获取下个月的同一天日期，处理超过下个月最大日期的情况
            let nextMonthSameDay = guding.add(0, 'month').date(currentDate.date());
            // 如果下个月没有这一天，取下个月的最后一天
            if (guding.format('DD') > nextMonthSameDay.format('DD')) {
                nextMonthSameDay = guding.endOf('month');
            }
            // 更新当前日期
            this.enddate = newDate.format('YYYY.MM.DD');
            this.tijiaotimer1 = newDate.format('YYYY-MM-DD');
            this.proximodate = nextMonthSameDay.format('YYYY.MM.DD');
            this.tijiaotimer2 = nextMonthSameDay.format('YYYY-MM-DD');
        },
        next() {
            if (this.withdrawalamount > this.datas.quota_money) {
                Toast('提款金额不能大于获批额度')
                return
            } else if (this.withdrawalamount == "") {
                Toast('请输入提款金额')
                return
            } else if (this.useListIndex == -1) {
                Toast('请选择提款期数')
                return
            } else if (this.purposeofloanIndex == -1) {
                Toast('请选择货款用途')
                return
            } else if (this.repaymentmethodIndex == -1) {
                Toast('请选择还款方式')
                return
            } else if (this.withdrawmoney == '') {
                Toast('请输入提款账号')
                return
            } else if (this.repaymentwithdrawal == "") {
                Toast('请输入还款账号')
                return
            }
            this.noClick = true
            this.$request({
                url: '/Index/withdrawal',
                data: {
                    examine_id: sessionStorage.getItem('examine_id'),
                    uid: sessionStorage.getItem('uid'),
                    money: Number(this.withdrawalamount),
                    periods: this.useList[this.useListIndex],
                    annual_interest_rate: this.datas.annual_interest_rate ? this.datas.annual_interest_rate : 0,
                    purpose: this.purposeofloan[this.purposeofloanIndex],
                    repayment: this.repaymentmethod[this.repaymentmethodIndex],
                    start_date: this.tijiaotimer,
                    end_date: this.tijiaotimer1,
                    first_repayment_date: this.tijiaotimer2,
                    fixed_repayment_date: this.No,
                    withdrawal_account: this.withdrawmoney,
                    repayment_account: this.repaymentwithdrawal,
                }
            }).then(res => {
                console.log(res, '提交金额成功');
                if (res.code == 1) {
                    this.$emit('send-data', 8);
                } else {
                    Toast(res.msg)
                }
                setTimeout(() => {
                    this.noClick = false
                }, 2000)
            }).catch(err => {
                setTimeout(() => {
                    this.noClick = false
                }, 2000)
                console.log(err, '提交金额失败');
                Toast(err.msg)
            })

        },
        pulldown(index) {


            this.classPicker = true
            this.currentIndex = index
            if (index == 1) {
                this.commonality = this.useList
            } else if (index == 2) {
                this.commonality = this.purposeofloan
            } else if (index == 3) {
                this.commonality = this.repaymentmethod
            }
        },
        setStudentClass(picker, values) {
            if (this.currentIndex == 1) {
                this.gettimer(picker)
            }
            this.classPicker = false
            if (this.currentIndex == 1) {
                this.useListIndex = values
            } else if (this.currentIndex == 2) {
                this.purposeofloanIndex = values
            } else if (this.currentIndex == 3) {
                this.repaymentmethodIndex = values
            }
        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
            const day = String(today.getDate()).padStart(2, '0');
            this.currentDate = `${year}.${month}.${day}`;
        },
        //获取菜单列表
        getmenuList(type) {
            this.$request({
                url: '/Index/get_select_data',
                data: {
                    // 7=提款期数，8=还款方式，9=贷款用途
                    type: type
                }
            }).then(res => {
                const list = [];
                res.data.forEach(item => {
                    list.push(item.name)
                });
                switch (type) {
                    case 6:
                        this.purposeofloan = list
                        break;
                    case 7:
                        this.useList = list
                        break;
                    case 8:
                        this.repaymentmethod = list
                        break;
                    default:
                        console.log("结束");
                }
            }).catch(err => {
                console.log(err, '下拉失败');
            })
        },
    },
    mounted() {
        this.gettimer(1)
    },
    watch: {
        show(newVal, oldVal) {
            if (newVal == true) {
                console.log('触发了');
                this.$nextTick(() => {
                    this.timer = setInterval(() => {
                        if (this.index <= 8) {
                            console.log(this.index);
                            this.getmenuList(this.index)
                            this.index = this.index + 1
                        } else {
                            this.purposeofloanIndex = this.application
                            this.index = 1
                            clearInterval(this.timer)
                        }
                    }, 100)
                })
            }
        }
    },
    computed: {},
    filters: {},
    components: {},
    beforeDestroy() {
        clearInterval(this.timer)
    },
}
</script>
<style>
.van-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.van-popup--center {
    background-color: rgba(0, 0, 0, 0);
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}
</style>
<style scoped lang="scss">
.box1 {
    padding: 70px 0;
    width: 75%;
    height: 1210px;
    // height: 80vh;
    overflow-y: auto;

    .tishi11 {
        font-weight: 400;
        font-size: 32px;
        color: #222222;
        text-align: left;
        margin-bottom: 20px;
    }
}

.box {
    width: 73vw;
    height: 1210px;
    // height: 87vh;
    background: #fff;
    box-sizing: border-box;
    border-radius: 0px 0px 32px 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .Withdrawal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .jine {
            width: 70%;
            height: 96px;
            background: #F4F4F4;
            border-radius: 8px;
            border: 0;
            font-weight: 400;
            font-size: 32px;
            color: #222222;
            padding-left: 31px;
        }

        div {
            font-weight: 400;
            font-size: 30px;
            color: #004ea2;
        }
    }

    .startstop {
        padding: 0 30px;
        padding-bottom: 40px;
        width: 100%;
        height: auto;
        margin: 0 auto;
        margin-bottom: 40px;
        background: #F4F4F4;
        border-radius: 8px;

        .qizhi {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
                font-weight: 400;
                font-size: 32px;
                color: #222222;
                margin-top: 40px;
            }
        }
    }

    .Drop_box {
        padding: 0 30px;
        width: 100%;
        height: 96px;
        margin: 0 auto;
        margin-bottom: 40px;

        background: #F4F4F4;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .yongtu {
            font-weight: 400;
            font-size: 32px;
            color: #222222;
        }

        .zhangha {
            font-weight: 400;
            font-size: 32px;
            color: #222222;
        }

        input {
            width: 80%;
            height: 100%;
            border: 0;
            background: rgba(0, 0, 0, 0);
            font-weight: 400;
            font-size: 32px;
            color: #222222;
            text-align: right;
        }

        .xuanze {
            display: flex;
            align-items: center;

            div {
                font-weight: 400;
                font-size: 32px;
                color: #666666;
                margin-right: 20px;
            }

            img {
                width: 25px;
                height: 25px;
            }
        }
    }

    .anniu {
        width: 620px;
        height: 100px;

        background: #004ea2;
        border-radius: 48px;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        border: 0;
        margin-top: 80px;
    }
}
</style>