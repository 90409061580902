<template>
    <div>
        <van-popup v-model="show" :close-on-click-overlay="false">
            <!-- v-if="datas.status == 1" -->
            <div class="box" v-if="datas.status == 1">
                <img src="../assets/image/ykd.png" alt="">
                <div class="xinxi">贷款审批结果</div>
                <div class="gongxi">{{ datas.msg }}</div>
                <div class="huoqi">获批额度</div>
                <div class="tihsi">
                    {{  datas.quota_money}} 元
                </div>
                <div class="lilv">
                    年利率 <span>{{ datas.annual_interest_rate }}%</span>
                </div>
                <div class="dts">
                    贷款利率以提款页面展示为准
                </div>
                <div class="qyrq">
                    签约有效期至{{ datas.sign_end_date }}
                </div>
                <button @click="next(1)">立即提款</button>
            </div>
            <div class="box1" v-if="datas.status == 0">
                <img src="../assets/image/gantan.png" alt="">
                <div class="xinxi">审核失败</div>
                <div class="tihsi">
                    {{ datas.msg }}
                </div>
                <button  @click="next(2)">返回首页</button>
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    name: 'Message',
    props: {
        show: {
            type: Boolean,
        },
        datas: {
            type: String,
        }
    },
    data() {
        return {
            noClick:false
        }
    },
    methods: {
        next(type) {
            if(type == 1){
                this.$emit('send-data', 7);
            }else if(type == 2){
                this.$emit('send-data', 10);
            }
            
        },
    },
    mounted() { },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style>
.van-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.van-popup--center {
    background-color: rgba(0, 0, 0, 0);
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}
</style>
<style scoped lang="scss">
.box1 {
    width: 73vw;
    height: 76vh;
    background: #fff;
    box-sizing: border-box;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 280px;

    img {
        width: 120px;
        height: 120px;
    }

    .xinxi {
        font-weight: bold;
        font-size: 46px;
        color: #222222;
        margin-top: 38px;
        margin-bottom: 98px;
    }

    .tihsi {
        font-weight: 400;
        font-size: 34px;
        color: #EF4300;
        line-height: 46px;
    }
    button {
        width: 300px;
        height: 100px;
        background: #004ea2;
        border-radius: 48px;
        border: 0;
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        margin-top: 100px;
    }
}

.box {
    width: 73vw;
    height: 95vh;
    background: #fff;
    box-sizing: border-box;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 250px;

    img {
        width: 144px;
        height: 45px;
    }

    .xinxi {
        font-weight: bold;
        font-size: 46px;
        color: #222222;
        margin-top: 38px;
        margin-bottom: 40px;
    }

    .gongxi {
        font-weight: 400;
        font-size: 34px;
        color: #222222;
    }

    .tihsi {
        font-weight: bold;
        font-size: 80px;
        color: #004ea2;
    }

    .huoqi {
        font-weight: bold;
        font-size: 34px;
        color: #222222;
        margin-top: 80px;
        margin-bottom: 20px;
    }

    .lilv {
        font-weight: 400;
        font-size: 34px;
        color: #222222;
        margin-top: 80px;

        span {
            color: #004ea2;
            font-weight: bold;
        }
    }

    .dts {
        font-weight: 400;
        font-size: 34px;
        color: #666666;
        margin-top: 40px;
    }

    .qyrq {
        width: 720px;
        height: 96px;
        background: rgba(0,78,162, 0.2);
        border-radius: 48px;
        font-weight: 400;
        font-size: 36px;
        color: #222222;
        line-height: 96px;
        margin-top: 60px;
    }

    button {
        width: 300px;
        height: 100px;
        background: #004ea2;
        border-radius: 48px;
        border: 0;
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        margin-top: 100px;
    }
}
</style>