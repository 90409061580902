<template>
    <div>
        <van-popup v-model="show" :close-on-click-overlay="false">
            <div class="registration_title">
                个人基础信息
                <img class="border_img" src="../assets/image/border.png" alt="">
            </div>
            <div class="box">
                <div class="xinxi_box">
                    <span>基础信息</span>
                    <div class="title">学历</div>
                    <div class="Drop_box" @click="pulldown(9)">
                        <div :style="{ 'color': educationIndex == -1 ? '#666666' : '#222222' }" class="choose">{{
                            educationIndex ==
                                -1 ? '请选择' : education[educationIndex] }}</div>
                        <img src="../assets/image/right.png" alt="">
                    </div>

                    <div class="title">婚姻状况</div>
                    <div class="Drop_box" @click="pulldown(1)">
                        <div :style="{ 'color': classIndex == -1 ? '#666666' : '#222222' }" class="choose">{{
                            classIndex ==
                                -1 ? '请选择' : classList[classIndex] }}</div>
                        <img src="../assets/image/right.png" alt="">
                    </div>

                    <div class="title">户口所在地</div>
                    <div class="Drop_box" @click="pulldown(2)">
                        <div :style="{ 'color': permanentaddress == '' ? '#666666' : '#222222' }" class="choose">{{
                            permanentaddress ==
                                '' ? '请选择' : permanentaddress }}</div>
                        <img src="../assets/image/right.png" alt="">
                    </div>

                    <div class="title">现居住地址</div>
                    <div class="Drop_box">
                        <input v-model="Currentlyliving" class="input_text" type="text" placeholder="请填写现居住地址">
                    </div>

                    <div class="title">拥有房屋类型</div>
                    <div class="Drop_box" @click="pulldown(3)">
                        <div :style="{ 'color': HousingIndex == -1 ? '#666666' : '#222222' }" class="choose">{{
                            HousingIndex ==
                                -1 ? '请选择' : Housingtype[HousingIndex] }}</div>
                        <img src="../assets/image/right.png" alt="">
                    </div>
                </div>

                <div class="xinxi_box">
                    <span>单位信息</span>
                    <div class="title">单位名称</div>
                    <div class="Drop_box">
                        <input v-model="unitname" class="input_text" type="text" placeholder="请输入">
                    </div>

                    <div class="title">所属行业</div>
                    <div class="Drop_box" @click="pulldown(4)">
                        <div :style="{ 'color': professionIndex == -1 ? '#666666' : '#222222' }" class="choose">{{
                            professionIndex ==
                                -1 ? '请选择' : profession[professionIndex] }}</div>
                        <img src="../assets/image/right.png" alt="">
                    </div>

                    <div class="title">月收入</div>
                    <div class="Drop_box">
                        <input v-model="monthlyincome" class="input_text" type="number" placeholder="请输入">
                        <div class="danwei">元</div>
                    </div>

                    <div class="title">公积金</div>
                    <div class="Drop_box">
                        <input v-model="reservefund" class="input_text" type="number" placeholder="请输入">
                        <div class="danwei">元</div>
                    </div>

                    <div class="title">单位类型</div>
                    <div class="Drop_box" @click="pulldown(5)">
                        <div :style="{ 'color': unitcategoryIndex == -1 ? '#666666' : '#222222' }" class="choose">{{
                            unitcategoryIndex ==
                                -1 ? '请选择' : unitcategory[unitcategoryIndex] }}</div>
                        <img src="../assets/image/right.png" alt="">
                    </div>

                    <template v-if="unitcategory[unitcategoryIndex] == '自有企业'">
                        <div class="title">营业执照名称</div>
                        <div class="Drop_box">
                            <input v-model="licence" class="input_text" type="text" placeholder="请输入">
                        </div>

                        <div class="title">社会统一信用代码</div>
                        <div class="Drop_box">
                            <input v-model="creditcode" class="input_text" type="text" placeholder="请输入">
                        </div>
                    </template>
                </div>

                <div class="xinxi_box">
                    <span>其他信息</span>
                    <div class="singlechoice">
                        <div class="warranty">保单信息</div>
                        <van-radio-group v-model="radio" checked-color="#004ea2">
                            <van-radio name="1">有投保</van-radio>
                            <van-radio name="0">无投保</van-radio>
                        </van-radio-group>
                    </div>

                    <template v-if="radio == 1">
                        <div class="title">投保公司</div>
                        <div class="Drop_box">
                            <input v-model="insurancecompany" class="input_text" type="text" placeholder="请输入">
                        </div>

                        <div class="title">年缴费</div>
                        <div class="Drop_box">
                            <input v-model="annualpayment" class="input_text" type="number" placeholder="请输入">
                            <div class="danwei">元</div>
                        </div>

                        <div class="title">缴费年限</div>
                        <div class="Drop_box">
                            <input v-model="paymentperiod" class="input_text" type="number" placeholder="请输入">
                            <div class="danwei">年</div>
                        </div>
                    </template>
                    <div class="singlechoice">
                        <div class="warranty">车产信息</div>
                        <van-radio-group v-model="radio1" checked-color="#004ea2">
                            <van-radio name="1">有车产</van-radio>
                            <van-radio name="0">无车产</van-radio>
                        </van-radio-group>
                    </div>

                    <template v-if="radio1 == 1">
                        <div class="title">详细信息</div>
                        <div class="Drop_box">
                            <input v-model="detailedinformation" class="input_text" type="text" placeholder="请输入">
                        </div>
                    </template>
                </div>

                <div class="xinxi_box">
                    <span>紧急联系人1</span>
                    <div class="title">姓名</div>
                    <div class="Drop_box">
                        <input v-model="name" class="input_text" type="text" placeholder="请输入">
                    </div>

                    <div class="title">与联系人关系</div>
                    <div class="Drop_box" @click="pulldown(6)">
                        <div :style="{ 'color': contactwithIndex == -1 ? '#666666' : '#222222' }" class="choose">{{
                            contactwithIndex ==
                                -1 ? '请选择' : contactwith[contactwithIndex] }}</div>
                        <img src="../assets/image/right.png" alt="">
                    </div>

                    <div class="title">电话</div>
                    <div class="Drop_box">
                        <input v-model="phone" class="input_text" type="number" placeholder="请输入">
                    </div>
                </div>

                <div class="xinxi_box">
                    <span>紧急联系人2</span>
                    <div class="title">姓名</div>
                    <div class="Drop_box">
                        <input v-model="name1" class="input_text" type="text" placeholder="请输入">
                    </div>

                    <div class="title">与联系人关系</div>
                    <div class="Drop_box" @click="pulldown(7)">
                        <div :style="{ 'color': contactwithIndex1 == -1 ? '#666666' : '#222222' }" class="choose">{{
                            contactwithIndex1 ==
                                -1 ? '请选择' : contactwith[contactwithIndex1] }}</div>
                        <img src="../assets/image/right.png" alt="">
                    </div>

                    <div class="title">电话</div>
                    <div class="Drop_box">
                        <input v-model="phone1" class="input_text" type="number" placeholder="请输入">
                    </div>
                </div>

                <div class="xinxi_box">
                    <span>紧急联系人3</span>
                    <div class="title">姓名</div>
                    <div class="Drop_box">
                        <input v-model="name2" class="input_text" type="text" placeholder="请输入">
                    </div>

                    <div class="title">与联系人关系</div>
                    <div class="Drop_box" @click="pulldown(8)">
                        <div :style="{ 'color': contactwithIndex2 == -1 ? '#666666' : '#222222' }" class="choose">{{
                            contactwithIndex2 ==
                                -1 ? '请选择' : contactwith[contactwithIndex2] }}</div>
                        <img src="../assets/image/right.png" alt="">
                    </div>

                    <div class="title">电话</div>
                    <div class="Drop_box">
                        <input v-model="phone2" class="input_text" type="number" placeholder="请输入">
                    </div>
                </div>
                <button :disabled="noClick" @click="tijiao" class="anniu">提交</button>
            </div>
        </van-popup>


        <van-popup v-model="classPicker" position="right">
            <van-picker show-toolbar :columns="commonality" @confirm="setStudentClass" @cancel="classPicker = false" />
        </van-popup>

        <van-popup style="width: 350px;" v-model="classPicker1" position="right" round class="area-content"
            :overlay="true" :close-on-click-overlay="true">
            <van-area title="所在地区" :area-list="permanent" :columns-placeholder="['请选择', '请选择', '请选择']"
                @confirm="areaConfirm" @cancel="classPicker1 = false" />
        </van-popup>
    </div>
</template>
<script>
import { Toast } from 'vant';

import { areaList } from '@vant/area-data';

export default {
    name: 'registration',
    props: {
        show: {
            type: Boolean,
            value: true,// true=显示 false=隐藏
        },
    },
    data() {
        return {
            classPicker: false,
            classPicker1: false,
            currentIndex: -1,//公共下标
            commonality: [],//公共弹框列表
            // 婚姻状况
            classList: [],
            classIndex: -1,
            //学历
            education: [],
            educationIndex: -1,
            //户口所在地
            permanent: areaList,
            permanentaddress: '',
            //现居住地址
            Currentlyliving: '',
            //拥又房屋类型
            Housingtype: [],
            HousingIndex: -1,
            //单位名称
            unitname: '',
            //所属行业
            profession: [],
            professionIndex: -1,
            //月收入
            monthlyincome: '',
            //公积金
            reservefund: '',
            //单位类目
            unitcategory: [],
            unitcategoryIndex: -1,
            //营业执照
            licence: '',
            //社会统一信用代码
            creditcode: '',
            //保单信息
            radio: '0',//1=有投保   0=无投保
            //投保公司
            insurancecompany: '',
            //年缴费
            annualpayment: '',
            // 缴费年限
            paymentperiod: '',
            //车产信息
            radio1: '0',
            //详细信息
            detailedinformation: '',
            //紧急联系人 姓名
            name: '',
            name1: '',
            name2: '',
            //与联系人关系
            contactwith: [],
            contactwithIndex: -1,

            contactwithIndex1: -1,

            contactwithIndex2: -1,
            //电话
            phone: '',
            phone1: '',
            phone2: '',

            index: 1,
            timer: null,
            noClick: false,
        }
    },
    methods: {
        //户口确定
        areaConfirm(picker, values) {
            if (picker[0] != undefined && picker[1] != undefined && picker[2] != undefined) {
                this.classPicker1 = false
                this.permanentaddress = picker[0].name + picker[1].name + picker[2].name
            } else {
                Toast('请选择所在地区')
            }

        },
        //确定菜单
        setStudentClass(picker, values) {
            this.classPicker = false
            if (this.currentIndex == 1) {
                this.classIndex = values
            } else if (this.currentIndex == 3) {
                this.HousingIndex = values
            } else if (this.currentIndex == 4) {
                this.professionIndex = values
            } else if (this.currentIndex == 5) {
                this.unitcategoryIndex = values
            } else if (this.currentIndex == 6) {
                this.contactwithIndex = values
            } else if (this.currentIndex == 7) {
                this.contactwithIndex1 = values
            } else if (this.currentIndex == 8) {
                this.contactwithIndex2 = values
            } else if (this.currentIndex == 9) {
                this.educationIndex = values
            }


        },
        //点击下拉菜单
        pulldown(index) {
            this.currentIndex = index
            if (index != 2) {
                this.classPicker = true
            }
            if (index == 1) {
                this.commonality = this.classList
            } else if (index == 2) {
                this.classPicker1 = true
            } else if (index == 3) {
                this.commonality = this.Housingtype
            } else if (index == 4) {
                this.commonality = this.profession
            } else if (index == 5) {
                this.commonality = this.unitcategory
            } else if (index == 6 || index == 7 || index == 8) {
                this.commonality = this.contactwith
            } else if (index == 9) {
                this.commonality = this.education
            }
        },
        //提交个人信息
        tijiao() {
            if (this.classIndex == -1) {
                Toast('请选择婚姻状况')
                return
            } else if (this.educationIndex == -1) {
                Toast('请选择学历')
                return
            } else if (this.permanentaddress == "") {
                Toast('请选择户口所在地')
                return
            } else if (this.Currentlyliving == '') {
                Toast('请填写现居住地址')
                return
            } else if (this.HousingIndex == -1) {
                Toast('请选择房屋类型')
                return
            } else if (this.unitname == '') {
                Toast('请填写单位名称')
                return
            } else if (this.professionIndex == -1) {
                Toast('请选择所属行业')
                return
            } else if (this.monthlyincome == '') {
                Toast('请填写月收入')
                return
            } else if (this.reservefund == '') {
                Toast('请填写公积金')
                return
            } else if (this.unitcategoryIndex == -1) {
                Toast('请选择单位类目')
                return
            } else if (this.licence == '' && this.unitcategory[this.unitcategoryIndex] == '自有企业') {
                Toast('请填写营业执照名称')
                return
            } else if (this.creditcode == '' && this.unitcategory[this.unitcategoryIndex] == '自有企业') {
                Toast('请填写社会统一信用代码')
                return
            } else if (this.insurancecompany == '' && this.radio == 1) {
                Toast('请填写投保公司')
                return
            } else if (this.annualpayment == '' && this.radio == 1) {
                Toast('请输入年缴费')
                return
            } else if (this.paymentperiod == '' && this.radio == 1) {
                Toast('请输入缴费年限')
                return
            } else if (this.detailedinformation == '' && this.radio1 == 1) {
                Toast('请输入详细信息')
                return
            } else if (this.name == '') {
                Toast('紧急联系人1,姓名不能为空')
                return
            } else if (this.phone == '') {
                Toast('紧急联系人1,电话不能为空')
                return
            } else if (this.contactwithIndex == -1) {
                Toast('请选择,紧急联系人1,与联系人关系')
                return
            }
            this.noClick = true
            this.$request({
                url: '/Index/add_post',
                data: {
                    examine_id: sessionStorage.getItem('examine_id'),
                    uid: sessionStorage.getItem('uid'),
                    marriage: this.classList[this.classIndex],
                    resident_address: this.permanentaddress,
                    current_residence: this.Currentlyliving,
                    education: this.education[this.educationIndex],
                    property_type: this.Housingtype[this.HousingIndex],
                    unit_name: this.unitname,
                    industry: this.profession[this.professionIndex],
                    monthly_income: this.monthlyincome,
                    accumulation_fund: this.reservefund,
                    unit_type: this.unitcategory[this.unitcategoryIndex],
                    corporate_name: this.licence,
                    credit_code: this.creditcode,
                    policy: Number(this.radio),
                    insurance_company: this.insurancecompany,
                    annual_payment: this.annualpayment,
                    payment_period: this.paymentperiod,
                    is_car: Number(this.radio1),
                    car_info: this.detailedinformation,
                    contacts1: this.name,
                    contacts1_phone: this.phone,
                    contacts1_relationship: this.contactwith[this.contactwithIndex],
                    contacts2: this.name1,
                    contacts2_phone: this.phone1,
                    contacts2_relationship: this.contactwith[this.contactwithIndex1],
                    contacts3: this.name2,
                    contacts3_phone: this.phone2,
                    contacts3_relationship: this.contactwith[this.contactwithIndex2],
                }
            }).then(res => {
                console.log(res, '注册');
                if (res.code == 1) {
                    this.$emit('send-data', 4);
                } else {
                    Toast(res.msg)
                }
                setTimeout(() => {
                    this.noClick = false
                }, 2000)
            }).catch(err => {
                setTimeout(() => {
                    this.noClick = false
                }, 2000)
                Toast(err.msg)
            })
        },
        //获取菜单列表
        getmenuList(type) {
            this.$request({
                url: '/Index/get_select_data',
                data: {
                    // 1=婚姻，2=房产类型，3=所属行业，4=单位类型，5=与联系人关系，6=申请用途
                    type: type
                }
            }).then(res => {
                const list = [];
                res.data.forEach(item => {
                    list.push(item.name)
                });
                switch (type) {
                    case 1:
                        this.classList = list
                        break;
                    case 2:
                        this.Housingtype = list
                        break;
                    case 3:
                        this.profession = list
                        break;
                    case 4:
                        this.unitcategory = list
                        break;
                    case 5:
                        this.contactwith = list
                        break;
                    case 10:
                        this.education = list
                        break;
                    default:
                        console.log("结束");
                }
            }).catch(err => {
                console.log(err, '下拉失败');
            })
        },
    },
    mounted() {
        this.$nextTick(() => {
                    this.timer = setInterval(() => {
                        if (this.index <= 5) {
                            console.log(this.index);
                            this.getmenuList(this.index)
                            this.index = this.index + 1

                        } else {
                            this.getmenuList(10)
                            this.index = 1
                            clearInterval(this.timer)
                        }
                    }, 100)
                })
    },
    watch: {
        show(newVal, oldVal) {
            if (newVal == true) {
                this.$nextTick(() => {
                    this.timer = setInterval(() => {
                        if (this.index <= 5) {
                            console.log(this.index);
                            this.getmenuList(this.index)
                            this.index = this.index + 1

                        } else {
                            this.getmenuList(10)
                            this.index = 1
                            clearInterval(this.timer)
                        }
                    }, 100)
                })
            }
        }
    },
    computed: {},
    filters: {},
    components: {},
    beforeDestroy() {
        clearInterval(this.timer)
    },
}
</script>
<style>

.van-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.van-popup--center {
    background-color: rgba(0, 0, 0, 0);
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}

.van-popup--right {
    min-width: 250px;
    border-radius: 10px;
}

.van-picker__confirm {
    color: #004ea2;
}

.van-radio-group {
    display: flex;
    align-items: center;
}

.van-radio__icon .van-icon {
    width: 18px;
    height: 18px;
    position: relative;
}

.van-icon-success:before {
    font-size: 13px;
    position: absolute;
    top: 60%;
    transform: translate(-50%, -50%);
    left: 50%;
}

.van-radio__label {
    font-weight: 400;
    font-size: 15px;
    color: #222222;
}

.van-radio {
    margin-left: 60px;
}
</style>
<style scoped lang="scss">
.registration_title {
    width: 73vw;
    height: 120px;
    background: #004ea2;
    border-radius: 32px 32px 0px 0px;
    line-height: 120px;
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}

.box {
    width: 73vw;
    height:1210px;
    background: #fff;
    padding: 70px 0px;
    box-sizing: border-box;
    border-radius: 0px 0px 32px 32px;
    overflow-y: auto;

    .xinxi_box {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 56px;

        .singlechoice {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 50px 0;

            .warranty {
                font-weight: 400;
                font-size: 28px;
                color: #222222;
            }
        }

        span {
            font-weight: bold;
            font-size: 34px;
            color: #222222;
            text-align: left;
            display: block;

        }

        .title {
            font-weight: 400;
            font-size: 28px;
            color: #222222;
            text-align: left;
            margin-bottom: 19px;
            margin-top: 39px;
        }

        .Drop_box {
            width: 100%;
            height: 80px;
            background: #F4F4F4;
            border-radius: 8px;
            padding: 0 30px 0 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .danwei {
                font-weight: 400;
                font-size: 28px;
                color: #222222;
            }

            .input_text {
                border: 0;
                font-weight: 400;
                font-size: 30px;
                color: #222222;
                background: rgba(0, 0, 0, 0);
                width: 100%;
                height: 100%;
            }

            .choose {
                font-weight: 400;
                font-size: 30px;
                color: #222222;
            }

            img {
                width: 25px;
                height: 25px;
            }
        }
    }

    .anniu {
        width: 40%;
        height: 100px;
        background: #004ea2;
        border-radius: 54px;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        border: 0;
        margin-top: 44px;
    }
}
</style>