<template>
    <div>
        <van-popup v-model="show" :close-on-click-overlay="false">
            <div class="registration_title">
                实名认证
                <img class="border_img" src="../assets/image/border.png" alt="">
            </div>
            <div class="box">
                <div class="Tips_box">
                    <div class="Tips_one">温馨提示</div>
                    <div class="Tips_tow">您上传的身份证照片，用于业务办理中的身份验证。</div>
                    <div class="Tips_tree">请上传身份证正反面信息</div>
                </div>
                <van-uploader :after-read="afterRead" accept="image/*" capture="camera">
                    <div class="standing" v-loading="loading">
                        <div class="standing_pai">
                            <img src="../assets/image/pai.png" alt="">
                            <div>上传人像面</div>
                        </div>
                        <img v-if="image1" class="shenfenimg" :src="url + image1" alt="">
                    </div>
                </van-uploader>
                <van-uploader  :after-read="afterRead1" accept="image/*" capture="camera">
                    <div class="standing one" v-loading="loading1">
                        <div class="standing_pai">
                            <img src="../assets/image/pai.png" alt="">
                            <div>上传国徽面</div>
                        </div>
                        <img v-if="image2" class="shenfenimg" :src="url + image2" alt="">
                    </div>
                </van-uploader>
                <button :disabled="noClick" @click="baocun" class="anniu">保存</button>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { Toast } from 'vant';

export default {
    name: 'registration',
    props: {
        show: {
            type: Boolean,
        },
        isShow1: {
            type: Number,
        },
    },
    data() {
        return {
            image1: '',
            image2: '',
            loading: false,
            loading1: false,
            url: 'https://loan.xingnuo.tech',
            noClick: false,
        }
    },
    methods: {
        afterRead1(file) {
            this.loading1 = true
            console.log(file);
            let data = {
                file: file.file
            }
            this.$axios.post('https://loan.xingnuo.tech/userapi/Common/upload', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                this.loading1 = false
                console.log('上传成功', response.data);
                if (response.data.code == 1) {
                    this.image2 = response.data.data.url
                }
                Toast('上传成功')
                // 处理上传成功的逻辑
            }).catch(error => {
                this.loading1 = false
                Toast('上传失败，请重新尝试')
                console.error('上传失败', error);
                // 处理上传失败的逻辑
            });
        },
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            this.loading = true
            console.log(file);
            let data = {
                file: file.file
            }
            this.$axios.post('https://loan.xingnuo.tech/userapi/Common/upload', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                this.loading = false
                console.log('上传成功', response.data);
                if (response.data.code == 1) {
                    this.image1 = response.data.data.url
                }
                Toast('上传成功')
                // 处理上传成功的逻辑
            }).catch(error => {
                this.loading = false
                Toast('上传失败，请重新尝试')
                console.error('上传失败', error);
                // 处理上传失败的逻辑
            });
        },
        //保存身份证
        baocun() {
            if (this.image1 == '') {
                Toast('请上传身份证人面像')
                return
            } else if (this.image2 == '') {
                Toast('请上传身份证上传国徽面')
                return
            }
            this.noClick = true
            this.$request({
                url: '/Index/save_img',
                data: {
                    uid: sessionStorage.getItem('uid'),
                    idcardz_img: this.image1,//正面照片
                    idcardf_img: this.image2//国徽照片
                }
            }).then(res => {
                console.log(res, '保存成功');
                if (res.code == 1) {
                    this.$emit('send-data', 3);
                    sessionStorage.setItem('examine_id', res.data.examine_id)
                    setTimeout(() => {
                        this.image1 = ""
                        this.image2 = ""
                        // Toast(res.msg)
                    }, 3000)
                } else {
                    Toast(res.msg)
                }
                setTimeout(() => {
                    this.noClick = false
                }, 2000)
            }).catch(err => {
                setTimeout(() => {
                    this.noClick = false
                }, 2000)
                console.log(err, '保存失败');
                Toast(err.msg)
            })

        },
    },
    mounted() { },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style>
.van-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.van-popup--center {
    background-color: rgba(0, 0, 0, 0);
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}
</style>
<style scoped lang="scss">
.registration_title {
    width: 73vw;
    height: 120px;
    background: #004ea2;
    border-radius: 32px 32px 0px 0px;
    line-height: 120px;
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}

.box {
    width: 73vw;
    height: 87vh;
    background: #fff;
    padding: 70px 0;
    box-sizing: border-box;
    border-radius: 0px 0px 32px 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;

    .one {
        background-image: url(../assets/image/fan.png) !important;
        margin-top: 39px;
    }

    .standing {
        width: 524px;
        min-height: 320px;
        background-image: url(../assets/image/zheng.png);
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .shenfenimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 15px;
            border: 1px solid #004ea2;
        }

        .standing_pai {
            width: 524px;
            min-height: 320px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 60px;
                height: 52px;
            }

            div {
                font-weight: 400;
                font-size: 30px;
                color: #222222;
                margin-top: 23px;
            }
        }
    }

    .Tips_box {
        width: 750px;
        text-align: left;
    }

    .Tips_one {
        font-weight: bold;
        font-size: 34px;
        color: #004ea2;
        text-align: left;
    }

    .Tips_tow {
        font-weight: 400;
        font-size: 32px;
        color: #444444;
        text-align: left;
        margin-top: 10px;
    }

    .Tips_tree {
        font-weight: bold;
        font-size: 34px;
        color: #222222;
        text-align: left;
        margin: 50px 0;
    }

    .anniu {
        width: 620px;
        min-height: 100px;
        background: #004ea2;
        border-radius: 54px;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        border: 0;
        margin-top: 80px;
    }
}
</style>