<template>
    <div>
        <van-popup v-model="show" :close-on-click-overlay="false">
            <div class="registration_title" :style="{'width':isShow == 1?'100vw':'','min-height':isShow == 1?'65px':'','borderRadius':isShow == 1?'0':''}">
                {{ isShow == 1 ? '账号登录' : '账号注册' }}
                <img class="border_img" src="../assets/image/border.png" alt="">
            </div>
            <div class="box" :style="{'width':isShow == 1?'100vw':'','height':isShow == 1?'90vh':'','borderRadius':isShow == 1?'0':''}">
                <input v-model="usernmae" type="text" :placeholder="isShow == 1 ? '请输入账号' : '请输入手机号'">
                <input v-model="password" v-if="isShow == 1" type="text" placeholder="请输入密码">
                <div v-if="isShow == 2" class="verify">
                    <input v-model="Verificationcode" type="text" placeholder="请输入验证码">
                    <div @click="getverify">{{ Countdown }}</div>
                </div>
                <button  @click="gologo" class="anniu">{{ isShow == 1 ? '立即登录' : '申请贷款' }}</button>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { Toast } from 'vant';

export default {
    name: 'registration',
    props: {
        show: {
            type: Boolean,
        },
        isShow: {
            type: Number,
        },
    },
    data() {
        return {
            usernmae: '',//账号
            password: '',//密码
            Verificationcode: '',
            Countdown: '获取',
            timer: null,
        }
    },
    methods: {
        //获取验证码
        getverify() {
            if (this.Countdown >= 1) return
            if (this.usernmae == "") {
                Toast('请输入手机号')
                return
            }
            this.$request({
                url: '/Common/send_sms',
                data: {
                    mobile: this.usernmae,
                }
            }).then(res => {
                console.log(res, '发送成功');
                Toast(res.msg)
                this.Countdown = 60;
                this.timer = setInterval(() => {
                    if (this.Countdown > 1) {
                        this.Countdown = this.Countdown - 1
                    } else {
                        this.Countdown = '获取'
                        clearInterval(this.timer)
                    }
                }, 1000)
            }).catch(err => {
                console.log(err, '发送失败');
                Toast(err.msg)
            })
        },
        //登录注册
        gologo() {
            if (this.isShow == 1) {
                if (this.usernmae == "") {
                    Toast('请输入登录账号')
                    return
                } else if (this.password == "") {
                    Toast('请输入登录密码')
                    return
                }
                this.$request({
                    url: '/Login/login',
                    data: {
                        user_name: this.usernmae,
                        password: this.password
                    }
                }).then(res => {
                    if (res.code == 1) {
                        this.$emit('send-data', 1);
                        sessionStorage.setItem('token', res.data.token)
                        setTimeout(() => {
                            this.usernmae = ""
                            this.password = ""
                            Toast(res.msg)
                        }, 3000)
                    } else {
                        Toast(res.msg)
                    }
                }).catch(err => {
                    Toast(err.msg)
                })
            } else if (this.isShow == 2) {
                if (this.usernmae == "") {
                    Toast('请输入手机号')
                    return
                } 
                else if (this.Verificationcode == "") {
                    Toast('请输入验证码')
                    return
                }
                this.$request({
                    url: '/Index/user_register',
                    data: {
                        mobile: this.usernmae,
                        captcha: this.Verificationcode
                    }
                }).then(res => {
                    console.log(res, '注册');
                    if (res.code == 1) {
                        this.$emit('send-data', 2);
                        sessionStorage.setItem('uid', res.data.uid)
                        setTimeout(() => {
                            this.usernmae = ""
                            this.password = ""
                            this.Verificationcode = ""
                            // Toast(res.msg)
                        }, 3000)
                    } else {
                        Toast(res.msg)
                    }
                }).catch(err => {
                    Toast(err.msg)
                })

            }
        },
    },
    mounted() {
    },
    watch: {},
    computed: {},
    filters: {},
    components: {},
    beforeDestroy() {
        clearInterval(this.timer)
    },
}
</script>
<style>
.van-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.van-popup--center {
    background-color: rgba(0, 0, 0, 0);
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}
</style>
<style scoped lang="scss">
.registration_title {
    width: 73vw;
    height: 120px;
    background: #004ea2;
    border-radius: 32px 32px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
    position: relative;
}

.box {
    width: 73vw;
    // height: 68vh;
    height: 940px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 0px 0px 32px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .verify {
        width: 51%;
        height: 108px;
        background: #F4F4F4;
        border-radius: 54px;
        margin: 0 auto;
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 40px;
        box-sizing: border-box;

        input {
            width: 100%;
            height: 50px;
            border-radius: 54px;
        }

        div {
            width: 120px;
            height: 60px;
            border-radius: 30px;
            border: 2px solid #004ea2;
            font-weight: bold;
            font-size: 32px;
            color: #004ea2;
            line-height: 60px;
        }
    }

    input {
        width: 51%;
        height: 108px;
        background: #F4F4F4;
        border-radius: 54px;
        border: 0;
        padding: 0;
        font-weight: 400;
        font-size: 36px;
        color: #222222;
        padding-left: 60px;
        box-sizing: border-box;
    }

    input:nth-child(2) {
        margin-top: 60px;
    }

    .anniu {
        width: 51%;
        height: 100px;
        background: #004ea2;
        border-radius: 54px;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        border: 0;
        margin-top: 100px;
    }
}
</style>