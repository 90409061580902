<template>
    <div>
        <van-popup v-model="show"  @click-overlay="back">
            <div class="box" v-if="is_success == 0">
                <img src="../assets/image/gantan.png" alt="">
                <div class="xinxi">提款失败</div>
                <div class="tihsi">
                    您的风险系数较高，提款失败 需要加入担保人提款
                </div>
            </div>
            <div class="box" v-if="is_success == 1">
                <img src="../assets/image/dui.png" alt="">
                <div class="xinxi">提款成功</div>
                <div class="tihsi">
                    请注意查收到账（当日工作日内到账）
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    name: 'Message',
    props: {
        show: {
            type: Boolean,
        },
        is_success: {
            type: Number,
        },
    },
    data() {
        return {}
    },
    methods: {
        back(){
            this.$emit('send-data', 10);
        },
    },
    mounted() {
    },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style>
.van-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.van-popup--center {
    background-color: rgba(0, 0, 0, 0);
    font-weight: bold;
    font-size: 46px;
    color: #FFFFFF;
}
</style>
<style scoped lang="scss">
.box {
    width: 73vw;
    height: 76vh;
    background: #fff;
    box-sizing: border-box;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 280px;

    img {
        width: 120px;
        height: 120px;
    }

    .xinxi {
        font-weight: bold;
        font-size: 46px;
        color: #222222;
        margin-top: 38px;
        margin-bottom: 98px;
    }

    .tihsi {
        font-weight: 400;
        font-size: 34px;
        color: #EF4300;
        line-height: 46px;
    }
}
</style>